<template>
  <statistics-calls />
</template>
<script>
import StatisticsCalls from "@/views/Statistics/StatisticsCalls.vue";
export default {
  components: { StatisticsCalls },
  name: "callcenter-statistics",
};
</script>
<style lang=""></style>
